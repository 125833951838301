<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">Carmel Samurai Company</h1>
      </v-card-title>
      <v-card-subtitle> </v-card-subtitle>
    </v-banner>
    <v-row>
      <v-col cols="12" class="mt-4 mx-4">
        Our story has been covered by various international sources over the
        past few years.
        <br />They do a good job of telling who we are and what we are trying to
        accomplish. <br />In this section, we shall let others tell the story.
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-card tile outlined elevation="5">
          <v-card-title class="ac font-weight-bold"
            ><h2>From a Japanese website</h2></v-card-title
          >
          <v-row class="mx-auto font-italic">
            <v-col cols="6" class="my-6">
              The 8 Samurai Series and the Pleiades Wakizashi Project are the
              creative idea of A. Taum Dell'Armo,Founder/Director, Carmel
              Samurai Company, Carmel. CA.<br /><br />
              Dell'Armo has studied 5 martial-arts including his passion, Iaido,
              The Way of the Samurai Sword.<br />
              Currently he studies the form, Zen Ken Ichi/Zen Sword which
              combines Iaido with Tai Chi Energy movement.
              <br /><br />
              Known in his country as a marketing expert, Dell'Armo was Senior
              VP with Ogilvy, International, the largest advertising agency in
              the world.<br />
              Previous to that he was Executive Director, Simon Marketing,Inc,
              Los Angeles, CA, the world's largest Promotions Company.<br />
              In that capacity he oversaw global promotions for clients like
              Coca-Cola, American Express, and McDonald's.<br /><br />
              He was also co-founder of the marketing consulting firm, The
              Madison Group, Inc.<br /><br />
              After retiring, Dell'Armo had the idea to merge his unique
              advertising/marketing skills with his love of samurai sword.<br />
              To do something that had never been attempted before.<br /><br />
              And the 8 Samurai Series and Pleiades Project were born.<br /><br />
              Dell'Armo lives in CA on the world-famous tourist area, Monterey
              Peninsula, home of Pebble Beach Golf Resort and the coastal resort
              town of Carmel-by-the-Sea.<br /><br /><br />
              He proudly states that Carmel Samurai Company was created to
              promote the Samurai Sword as Fine Art.<br />
              And he should know. His town boasts more art galleries per capita
              than any other in the United States.
            </v-col>
            <v-col cols="6">
              <Gallery :images="samuraiImages" :contain="false"></Gallery>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-card
          target="_blank"
          href="/media/about-2.pdf"
          tile
          outlined
          elevation="5"
        >
          <v-card-title class="ac font-weight-bold"
            ><h2>Cover Story, Carmel Living Magazine</h2></v-card-title
          >
          <v-row class="mx-auto font-italic my-6">
            <v-col cols="6">
              The Monterey Peninsula in general—and Carmel in particular— has
              long been a place that attracts one-of-a-kind characters and
              outsized personalities. Think Steinbeck, Day, Ricketts, Morse,
              Eastwood.
              <br /><br />These people share several traits, but one in stands
              out: a passion for life and for the endeavors they engage in. It’s
              no stretch to add A. Taum Dell’Armo to that list <br /><br />
              Taum (pronounced just like “Tom,” the name he grew up with in the
              South Bronx) long held a dream to live in Carmel. “I used to call
              it my ‘Someday Place,’” he says. “I first visited in 1972 and
              vowed that I would move here someday.” “Someday” became “today” 16
              years ago when he and his wife Diane took up residence here. As is
              so often the case, the route from his home in The Bronx to Carmel
              was somewhat circuitous.
              <br /><br />Click to read more..
            </v-col>
            <v-col cols="6">
              <v-img contain height="400" :src="aboutPreview[0].pic"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-card
          target="_blank"
          href="/media/about-3.pdf"
          tile
          outlined
          elevation="5"
        >
          <v-card-title class="ac font-weight-bold"
            ><h2>Pine Cone - Article/ Great Lives</h2></v-card-title
          >
          <v-row class="mx-auto font-italic my-6">
            <v-col cols="6">
              He's a native New Yorker, a seamless relentless East Coast talker
              who, when discussing his favorite topc - Samurai Swords - rarely
              comes up for air.<br /><br />
              "Next to my family, my sword is the most important thing in my
              life. It's really hard to even put into words," said Taum
              Dell'Armo, a 71-year-old Carmel Valley man who enjoyed a
              fortuitous life that included a lucrative career as an advertising
              executive, but found his bliss 12 years ago when he started
              studying "the way of the Samurai."

              <br /><br />Click to read more..
            </v-col>
            <v-col cols="6">
              <v-img contain height="400" :src="aboutPreview[1].pic"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-card
          target="_blank"
          href="/media/about-4.pdf"
          tile
          outlined
          elevation="5"
        >
          <v-card-title class="ac font-weight-bold"
            ><h2>Baruch Magazine</h2></v-card-title
          >
          <v-row class="mx-auto font-italic my-6">
            <v-col cols="6">
              <h3>
                From the Bronx to the Dojo:<br />
                The Samurai Journey of Taum Dell’Armo
              </h3>
              <br /><br />How does a high-powered advertising executive find
              inner peace? For alumnus Taum Dell’Armo (’69), the answer lies in
              the way of the samurai. <br />The Bronx-born Dell’Armo—whose first
              name, “Taum,” is based on a Sanskrit holy word and is pronounced
              just like his given name, “Tom”—is trained in five martial arts
              and has been studying Iaido, an intricate Japanese sword art, for
              the last 12 years. <br /><br />
              Click to read more...
            </v-col>
            <v-col cols="6">
              <v-img contain height="400" :src="aboutPreview[2].pic"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-card
          target="_blank"
          href="/media/about-5.pdf"
          tile
          outlined
          elevation="5"
        >
          <v-card-title class="ac font-weight-bold"
            ><h2>Carmel Magazine</h2></v-card-title
          >
          <v-row class="mx-auto font-italic my-6">
            <v-col cols="6">
              <h3>
                Taum Dell`Armo is a master of several martial arts methods and
                collects Samurai swords.<br />
                He creates custom swords for clients using antique components.
              </h3>
              <br /><br />
              Taum Dell`Armo calls his "8 Samurai" The Most Exclusive Art Club
              in the World. And he's no doubt correct.
              <br /><br />
              Click to read more...
            </v-col>
            <v-col cols="6">
              <v-img contain height="400" :src="aboutPreview[3].pic"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-card
          target="_blank"
          href="https://carmelwomansclubca.org/event/the-way-of-the-samurai/"
          tile
          outlined
          elevation="5"
        >
          <v-card-title class="ac font-weight-bold"
            ><h2>Carmel's Women's Club</h2></v-card-title
          >
          <v-row class="mx-auto font-italic my-6">
            <v-col cols="6">
              <h3>
                Lecturer and Scholar Taum Dell’Armo is presenting “The Way of
                the Samurai”
              </h3>
              <br /><br />
              The presentation will include a display of exquisitely crafted
              gold and silver samurai sword fittings and a history of the
              meticulous workmanship that made the ancient sword one of the most
              treasured pieces of art in the world
              <br /><br />
              Click to read more...
            </v-col>
            <v-col cols="6">
              <v-img contain height="400" :src="aboutPreview[4].pic"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-card tile outlined elevation="5">
          <v-card-title class="ac font-weight-bold"
            ><h2>Meals on wheels</h2></v-card-title
          >
          <v-row class="mx-auto font-italic my-6">
            <v-col cols="6">
              <h3>
                Dear Friend, so sorry to be slow in thanking you for adding so
                much to our Sunday Meals on Wheels Gala Fundraising night.
              </h3>
              <br /><br />
              Taum, you were totally the “centerpiece” and made our table over
              the top.
              <br /><br />
              Thank you from the bottom of my heart for being so generous with
              both sharing your sword and that culture with everyone, and in
              helping.
              <br /><br />
              JUDIE PROFETA<br />
              Carmel Realty Company<br />
            </v-col>
            <v-col cols="6">
              <v-img contain height="400" :src="aboutPreview[5].pic"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card tile outlined elevation="5">
          <v-card-title class="ac font-weight-bold"
            ><h2>
              Guest Lecturer at Sagewood Center, Scottsdale, AZ (The Art of the
              Samurai - 2022 National Tour)
            </h2></v-card-title
          >
          <v-row class="mx-auto font-italic my-6">
            <v-col cols="6">
              <v-img contain height="400" :src="aboutPreview[6].pic"></v-img>
            </v-col>
            <v-col cols="6">
              <v-img contain height="400" :src="aboutPreview[7].pic"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Gallery from "@/components/Gallery";
export default {
  name: "About",
  components: {
    Gallery,
  },
  metaInfo() {
    return {
      title: "About Carmel Samurai Company",
      meta: [
        {
          name: "description",
          content:
            "he Pleiades – The 7 Sisters Star Constellation was made possible by the creative idea of A. Taum Dell’Armo, Founder/Director, Carmel Samurai Company, Carmel, CA, USA and the master craftsmanship of the world-renowned master swordsmith Ichiro Matsuba Kunimasa",
        },
        {
          property: "og:title",
          content: "The Carmel Samurai Company",
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      aboutPreview: [
        {
          pic: require("@/assets/media/about-2.png"),
        },
        {
          pic: require("@/assets/media/about-3.png"),
        },
        {
          pic: require("@/assets/media/about-4.png"),
        },
        {
          pic: require("@/assets/media/about-5.png"),
        },
        {
          pic: require("@/assets/100-about.jpeg"),
        },
        {
          pic: require("@/assets/media/about-7.jpg"),
        },
        {
          pic: require("@/assets/media/about-8.png"),
        },
        {
          pic: require("@/assets/media/about-9.png"),
        },
      ],
      samuraiImages: [
        {
          id: 1,
          imgSrc: require("@/assets/company/samuraiTaum.jpeg"),
          ratio: "1.7",
        },
      ],
    };
  },
};
</script>
<style>
#hero .v-parallax__content {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.45) 25%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.45) 75%,
    rgba(0, 0, 0, 1) 100%
  );
}
h3.ac {
  font-size: 22px;
}
h3 {
  font-size: 18px;
  line-height: 1.2;
}
</style>

<template>
  <v-container>
    <v-row>
      <v-col>{{ pics }}</v-col>
    </v-row>
    <v-row>
      <v-row>
        <v-col v-for="(item, no) in items" :key="item.id">
          <v-card class="mx-auto" max-width="344" @click="openPicture(no)">
            <v-img :src="item.pic" height="200px"> </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog
      v-model="showDialog"
      min-width="350"
      max-width="80%"
      @keydown.left="picBack()"
      @keydown.right="picForward()"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showDialog = false">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-img contain max-height="800" :src="items[activePicture].pic">
          <v-btn
            x-large
            style="position: absolute; left: -0px; top: 40%"
            @click="picBack()"
          >
            <v-icon x-large color="">arrow_back</v-icon>
          </v-btn>
          <v-btn
            x-large
            style="position: absolute; right: -10px; top: 40%"
            @click="picForward()"
          >
            <v-icon x-large color="">arrow_forward</v-icon>
          </v-btn>
        </v-img>
        <v-card-text
          class="mx-auto title"
          v-html="items[activePicture].text"
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "About",
  components: {},
  props: ["items"],
  data() {
    return {
      showDialog: false,
      activePicture: 1,
    };
  },
  methods: {
    picForward() {
      if (this.activePicture + 1 > this.items.length) {
        this.activePicture = 0;
      } else this.activePicture++;
    },
    picBack() {
      if (this.activePicture === 0) {
        this.activePicture = this.items.length - 1;
      } else this.activePicture--;
    },
    openPicture(id) {
      if (typeof this.items[id] !== "undefined") {
        this.activePicture = id;
        this.showDialog = true;
      }
    },
  },
};
</script>
<style>
.footerText {
}
div.sold {
  height: 25px;
  width: 180px;
  background-color: #9c1b21;
  position: absolute;
  top: 40px;
  right: -10px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  white-space: nowrap;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  font-family: "Apple Chancery", "Helvetica Neue", Helvetica;
}

.sold:before {
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent;
  border-width: 37px;
  border-left-width: 53px;
  border-right-width: 26px;
  content: "";
  display: block;
  left: -49px;
  position: absolute;
  width: 141%;
  border-top-width: 0px;
  border-bottom-color: #9c1b21;
  top: -2px;
  z-index: -1;
}
</style>

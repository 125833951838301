<template>
  <v-row :id="idName" class="mt-8">
    <v-col cols="12">
      <h2 class="ac mx-4">{{ title }}</h2>
    </v-col>
    <v-col cols="12">
      <v-row class="mx-4" v-html="content"></v-row>
      <v-row class="mx-4"
        ><v-col>{{ author }}</v-col></v-row
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Teaching",
  props: ["idName", "title", "content", "author"],
};
</script>

<style scoped></style>

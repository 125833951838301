<template>
  <v-app>
    <v-app-bar v-if="!hideNav" absolute app dense>
      <v-tabs>
        <v-tab
          v-for="item in topLinks"
          :key="item.name"
          class="white--text"
          :to="item.link"
          >{{ item.name }}</v-tab
        >
        <v-tab>
          <v-menu open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on"> More </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(viewMore, n) in viewMoreLinks"
                :key="n"
                :to="viewMore.link"
              >
                <v-list-item-title>{{ viewMore.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="text-break">
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer color="primary lighten-1" padless app absolute>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="link in links"
          :key="link.name"
          color="white"
          text
          plain
          class="my-2"
          :to="link.link"
        >
          {{ link.name }}
        </v-btn>
      </v-row>

      <v-row justify="center" no-gutters>
        <v-btn
          v-for="link in linksRowTwo"
          :key="link.name"
          color="white"
          text
          plain
          class="my-2"
          :to="link.link"
        >
          {{ link.name }}
        </v-btn>
        <v-col class="primary py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} —
          <strong>Carmel Samurai Company</strong>
          - {{ versionNo }}
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  name: "AppSite",
  metaInfo() {
    return {
      title: "Carmel Samurai Company",
      meta: [
        { name: "description", content: "Swords of Art & Elegance" },
        { property: "og:title", content: "Carmel Samurai Company" },
        { property: "og:site_name", content: "www.carmelsamuraicompany.com" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      imgLink: {
        logo: require("@/assets/24-TosoguDragon.jpg"),
      },
      topLinks: [
        { name: "Cover Page", link: "/" },
        { name: "Home", link: "/home/" },
        { name: "The 8 Samurai Series", link: "/the-8-samurai-series" },
        { name: "The Pleiades Wakizashi Project", link: "/the-pleiades" },
        { name: "Zen Teaching Stories", link: "/zen-teachings" },
        { name: "About Us", link: "/about/" },
        { name: "Contact", link: "/contact" },
      ],
      viewMoreLinks: [
        { name: "Zen Teaching Stories", link: "/zen-teachings" },
        { name: "Swords, People & Other Stuff", link: "/swords-and-people" },
        { name: "Glossary Terms", link: "/glossary" },
        { name: "Links", link: "/links" },
      ],
      links: [
        { name: "Cover Page", link: "/" },
        { name: "Home", link: "/home/" },
        { name: "The 8 Samurai Series", link: "/the-8-samurai-series" },
        {
          name: "The Pleiades/7Sisters Wakizashi Project",
          link: "/the-pleiades",
        },
        { name: "About Us", link: "/about/" },
      ],
      linksRowTwo: [
        { name: "Zen Teaching Stories", link: "/zen-teachings" },
        { name: "Swords, People & Other Stuff", link: "/swords-and-people" },
        // { name: "Terms of Service", link: "/" },
        // { name: "Media", link: "/" },
        // { name: "News & Exhibitions", link: "/" },
        { name: "Glossary Terms", link: "/glossary" },
        { name: "Contact Us", link: "/contact" },
        { name: "Links", link: "/links" },
      ],
    };
  },
  computed: {
    hideNav() {
      return this.$route.meta.hideNavbar;
    },
    versionNo() {
      return ` ${this.$store.state.buildNumber}.${this.$store.state.versionNumber}`;
    },
  },
};
</script>

<style>
h1 {
  font-family: "Apple Chancery", "Helvetica Neue", Helvetica;
}

.ac {
  font-family: "Apple Chancery", "Helvetica Neue", Helvetica;
  line-height: 2rem;
}
#hero .v-parallax__content {
  background: linear-gradient(70deg, black, transparent);
}
.simpleText {
  text-decoration: none;
  color: #fff !important;
}
.chapterStart {
  font-size: 14pt;
  font-weight: bold;
}
</style>

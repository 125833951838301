<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">The Samurai Sword as Fine Art</h1>
      </v-card-title>
    </v-banner>
    <v-banner>
      <v-img id="hero" dark :src="imgLink.background"> </v-img>
    </v-banner>
    <v-row class="mt-8">
      <v-col cols="12">
        <div class="mx-4">
          <span class="ac chapterStart">Carmel Samurai Company </span> creates
          original masterpieces; swords of art & elegance by legendary Japanese
          Masters for serious art collectors.<br /><br />
          We normally carry no stock; no “inventory” as such. Everything we do
          is custom; one-one-one with our clients.<br /><br />
          Since our inception in 2017 we have operated by referral only.<br />
          No advertising. No website. We have never published a phone number,
          address, or email.<br />
          Everything was “word-of-mouth” and a growing reputation among art
          collectors and brokers.<br />
          Everything, personal.<br />
          Our clients come to us.
          <v-banner></v-banner>
          <v-banner class="mb-4">
            <v-row style="font-style: italic; line-height: 24px">
              <v-col cols="12" md="4" lg="4" xl="4">
                Obviously, we are not a mass-production company.<br />
                And, as such, we were not “open” to a public audience.<br /><br />
                Then in late 2021 we were invited to an exclusive exhibit of our
                <router-link
                  class="white--text font-weight-bold"
                  to="/the-8-samurai-series"
                  plain
                  text
                  >8 Samurai Series</router-link
                >, the very first of its kind, at the art capital of the United
                States (Carmel-by-the-Sea, CA). In a world famous resort city
                that boasts more art galleries per capita than any other, we
                broke the tradition.<br />
              </v-col>
              <v-col cols="12" md="4" lg="4" xl="4">
                The result was highly favorable media coverage, a remarkable
                invite to exhibit at one of America’s most prestigious art
                museums, and coverage on several international websites. But now
                that we <b>are</b> being covered by other websites, we recognize
                the importance of the creation of our own so that we “may
                control our own narrative”; tell our own story.<br /><br />
                So, here it is.<br />
                As of today, 3/1/22, we are on the internet.<br />
                But we are not a company for everyone interested in “Samurai
                Sword.”<br />
              </v-col>
              <v-col cols="12" md="4" lg="4" xl="4">
                Our clients are not normally samurai sword collectors.<br />
                We do not deal in “historic” swords - although ALL the fittings
                on our custom creations are genuine samurai antiques from
                private collectors and museums all over the world.<br />
                Many are several hundred years old. But all our blades are
                custom-forged for each client.<br />
                <br />
                <span style="font-size: 13px"
                  >(As a side note: we can and have served as a referral for those who are interested in antique historic swords ).</span
                >
              </v-col>
            </v-row>
          </v-banner>
          <span class="text-h6 font-weight-bold"
            >But some things will not change.<br />Our mission was, and is, to
            present the custom-made Samurai Sword as Fine Art.</span
          ><br /><br />

          Our goal when we began was to bring these amazing works of art to the
          general art world.<br />
          And so, we created something unique, something never attempted
          before.<br />
        </div>
        <div class="mt-8">
          <router-link
            class="text--white white--text"
            to="/the-8-samurai-series"
          >
            <h1 class="ac mb-4 text-center">The 8 Samurai Series</h1>
            <h2 class="ac text-center">
              The Most Exclusive Art Club in the World
            </h2>
          </router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "HomePage",

  data() {
    return {
      imgLink: {
        background: require("@/assets/homePage.jpeg"),
      },
    };
  },
};
</script>

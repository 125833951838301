<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">
          #{{ activeSword.number }} {{ activeSword.name }}
          <i v-if="activeSword.isSold"> - SOLD</i>
          <i v-if="activeSword.onSale"> - For Sale</i>
          <i v-if="activeSword.comingSoon"> - Coming Soon</i>
        </h1>
      </v-card-title>

      <v-card-subtitle> </v-card-subtitle>
    </v-banner>
    <v-banner>
      <v-img max-height="350" contain dark :src="activeSword.cover"></v-img>
    </v-banner>
    <v-row class="mt-2">
      <v-col cols="12" class="mx-4">
        <p
          v-for="text in activeSword.text"
          :key="text.id"
          class="mb-0 pb-0"
          v-html="text.content"
        ></p>
      </v-col>
      <v-col v-if="activeSword.gallery.length > 0" cols="12">
        <v-card-title class="text-break"
          >Click on each image to enlarge and use the arrow keys to
          navigate.</v-card-title
        >
        <vue-picture-swipe :items="activeSword.gallery"></vue-picture-swipe>
      </v-col>
      <v-col
        v-if="activeSword.gallery.length > 0 || activeSword.text.length > 0"
        ><v-divider></v-divider
      ></v-col>
      <v-col cols="12"><h2 class="mb-2">The Sisters</h2></v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="sword in related"
        :key="sword.id"
        cols="12"
        md="3"
        lg="3"
        sm="12"
      >
        <v-card class="mx-auto" :to="sword.url">
          <v-img :src="sword.cover" height="200px" contain></v-img>
          <v-card-title> #{{ sword.number }} {{ sword.name }} </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SamuraiPage",
  components: {},
  metaInfo() {
    return {
      title: this.activeSword.name,
      meta: [
        {
          name: "description",
          content: this.activeSword.name + " - " + this.activeSword.text[0],
        },
        {
          property: "og:title",
          content:
            "8 Master swordsmiths forge 8 blades for 8 select clients. - " +
            this.activeSword.name,
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      swords: [
        {
          number: 0,
          name: "Placeholder",
          isSold: true,
          text: "Placeholder",
          gallery: [],
        },
        {
          number: 1,
          name: "Kaida/Little Dragon",
          isSold: true,
          onSale: false,
          comingSoon: false,
          cover: require("@/assets/7sisters/1-cover.jpeg"),
          text: [
            {
              id: 1,
              content:
                "The first in the historic, first-of-its kind, Wakizashi series.<br>The beautiful creation has found an honored place in the home of a great American art<br>collector. It is his first introduction to the world of Samurai Sword and we are proud he chose us (and this sword) for his renowned collection.<br>The fittings are the classic Dragon, most powerful of all samurai symbols.<br>In Japanese lore, books are filled with the many meanings and the myths, legends and stories of this creature. Unlike the West, dragons in the eastern culture are not evil. They do not waste their time hoarding treasure piles of gold (which they could have absolutely no use for),guarding virginal maidens (never could figure that one out), or waging indiscriminate<br>destruction. Seriously, don’t they have better things to do?<br>To the Samurai they can symbolize a virtue (honor, courage, devotion, etc). They can “stand” for an obstacle, a weakness, a character flaw. Something that must be defeated, overcome or understood: excessive pride, fear, ignorance, jealousy.<br>They are always a Power symbol. one of the top 3.<br>(Bet you’re wondering what the others are!).<br>We named this sword after Alycone, the brightest of the constellation.<br>Nicknamed “White Tiger” for its’ ferocious brilliance and steady light.<br>Six more wakizashi will be created.<br>The First is sold, but the others await their future owners.",
            },
          ],
          gallery: [
            {
              id: 1,
              src: require("@/assets/7sisters/1-1.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/1-1_thumnbail.jpeg"),
              w: 1280,
              h: 650,
            },
            {
              id: 2,
              src: require("@/assets/7sisters/1-2.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/1-2_thumnbail.jpeg"),
              w: 1280,
              h: 650,
            },
            {
              id: 3,
              src: require("@/assets/7sisters/1-3.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/1-3_thumnbail.jpeg"),
              w: 1600,
              h: 850,
            },
            {
              id: 4,
              src: require("@/assets/7sisters/1-4.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/1-4_thumnbail.jpeg"),
              w: 2100,
              h: 440,
            },
          ],
          extraData: {
            content: "",
            gallery: [],
          },
        },
        {
          number: 2,
          name: "Dream Catcher",
          isSold: true,
          onSale: false,
          comingSoon: false,
          cover: require("@/assets/7sisters/2-cover.jpeg"),
          text: [
            {
              id: 1,
              content:
                "A very special sword with classic symbols of Good Fortune, Health, and Long Life.<br>The tradition goes back many years and generations.<br>Gathering on the banks of the river on New Years’ Eve as the Treasure Boat sails into view, the people make drawings of the ship. That night they reverently place the drawings under their sleeping mat and dream of “all things good and beautiful.”<br><br>The contents of the ship (Takaramono) all have a story and connection with the 7 Gods of Luck.<br> The menuki are Hotei, the Laughing Buddha (most popular of the 7) and he carries the bag of Precious Things.<br><br>This is a classic takarafune design inlaid with gold and silver.<br>The deep blue-black background of the kogai and kozuka is shakudo; copper mixed with gold.<br><br>Notice the tiny “dots” on the surface, all hand struck and perfectly aligned.<br>Takarafune, believed to be “the dream catcher” during the Murumachi Period.<br>This is a graceful, impressive artistic set (17th century) with an amazing hand-cut gold habaki and a blade of highest quality hamon. The fittings have passed Shinza (rating) and come with certification papers from the NBTHK.<br><br>We name each sword after a star in the Pleiades constellation.<br>Each star name is chosen specifically for each owner.<br>Does this one have your name on it?",
            },
          ],
          extraData: {
            content: "",
            gallery: [],
          },
          gallery: [
            {
              id: 1,
              src: require("@/assets/7sisters/2-1.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/2-1_thumnbail.jpeg"),
              w: 1600,
              h: 850,
            },
            {
              id: 2,
              src: require("@/assets/7sisters/2-2.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/2-2_thumnbail.jpeg"),
              w: 1600,
              h: 850,
            },
          ],
        },
        {
          number: 3,
          name: "Carp/Dragon’s Gate",
          isSold: true,
          onSale: false,
          comingSoon: false,
          cover: require("@/assets/7sisters/carp/B.jpeg"),
          text: [
            {
              id: 1,
              content:
                "Based on <a href='/#/zen-teachings' class='ac white--text font-weight-bold'>the legend of Dragon Gate</a>",
            },
            {
              id: 2,
              content:
                "The story of a common carp that braves the waterfall that descends from the legendary top of the holy mountain. ",
            },
            {
              id: 3,
              content:
                "By passing through the “Dragon-Gate” that marks the boundary between the physical and the spiritual, a carp can be rewarded by the gods and be transformed into a mighty dragon.",
            },
            {
              id: 4,
              content:
                "This is a sword representing courage, perseverance, accomplishment, and destiny. It will be a beautiful and spiritually powerful sword for a worthy owner.",
            },
            {
              id: 5,
              content:
                "<br><br><h3>WOW! This one didn't last long. We produced this on \"spec\" knowing it was to be one of our most ambitious projects.<br>Almost immediately delivery, even before we could list it on this site, it found a home in a fabulous private collection of international art. The client's first samurai item!<br><br>Starting with a unique and artistically amazing tosogu set of fittings from the 17th century, we invested heavily in hiring one of Japan's greatest artists to create a saya of gold, silver, urushi, maki-e and creative design. This brings the legend to life and makes this sword a true work of art.<br><br>Master Matsuba has forged one of his finest blades; beautiful hamon, hada, sori, and a magical presentation.<br><br>This sets the bar very, very high for future swords in the 7 Sisters Series.<br><br><b>Stay tuned for our next announcement or....contact us today for your personal custom creation.</b></h3>",
            },
          ],
          extraData: {
            content: "",
            gallery: [],
          },
          gallery: [
            {
              id: 1,
              src: require("@/assets/7sisters/3-1.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/3-1_thumnbail.jpeg"),
              w: 870,
              h: 850,
            },
            {
              id: 2,
              src: require("@/assets/7sisters/3-2.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/3-2_thumnbail.jpeg"),
              ratio: "1",
              w: 870,
              h: 850,
            },
            {
              id: 3,
              src: require("@/assets/7sisters/3-3.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/3-3_thumnbail.jpeg"),
              ratio: "1",
              w: 870,
              h: 850,
            },
            {
              id: 4,
              src: require("@/assets/7sisters/3-4.jpeg"),
              thumbnail: require("@/assets/7sisters/thumbnails/3-4_thumnbail.jpeg"),
              ratio: "1",
              w: 1200,
              h: 500,
            },
            {
              id: 5,
              src: require("@/assets/7sisters/carp/K.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/K.jpeg"),
              ratio: "1",
              w: 500,
              h: 500,
            },
            {
              id: 6,
              src: require("@/assets/7sisters/carp/H.jpeg"),
              ratio: "1",
              w: 1200,
              h: 500,
            },

            {
              id: 7,
              src: require("@/assets/7sisters/carp/D.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/D.jpeg"),
              ratio: "1",
              w: 1800,
              h: 500,
            },

            {
              id: 8,
              src: require("@/assets/7sisters/carp/I.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/I.jpeg"),
              ratio: "1",
              w: 1800,
              h: 500,
            },
            {
              id: 9,
              src: require("@/assets/7sisters/carp/C.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/C.jpeg"),
              ratio: "1",
              w: 1800,
              h: 500,
            },
            {
              id: 10,
              src: require("@/assets/7sisters/carp/G.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/G.jpeg"),
              ratio: "1",
              w: 1800,
              h: 500,
            },
            {
              id: 11,
              src: require("@/assets/7sisters/carp/E.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/E.jpeg"),
              ratio: "1",
              w: 1800,
              h: 500,
            },
            {
              id: 12,
              src: require("@/assets/7sisters/carp/J.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/J.jpeg"),
              ratio: "1",
              w: 1800,
              h: 500,
            },
            {
              id: 13,
              src: require("@/assets/7sisters/carp/A.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/A.jpeg"),
              ratio: "1",
              w: 1800,
              h: 500,
            },
            {
              id: 14,
              src: require("@/assets/7sisters/carp/F.jpeg"),
              thumbnail: require("@/assets/7sisters/carp/F.jpeg"),
              ratio: "1",
              w: 1800,
              h: 500,
            },
          ],
        },
      ],
    };
  },
  computed: {
    related() {
      let list = [];
      for (let i = 1; i < this.swords.length; i++) {
        if (this.activeSword.number != this.swords[i].number) {
          list.push({
            number: this.swords[i].number,
            name: this.swords[i].name,
            cover: this.swords[i].cover,
            url:
              "/the-pleiades/" +
              this.$urlHelper.fixDiacritice(this.swords[i].name) +
              "-" +
              this.swords[i].number,
          });
        }
      }
      return list;
    },
    activeSword() {
      if (typeof this.swords[this.$route.params.id] === "undefined") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$router.push({ path: "/404" });
      }
      if (this.$route.params.id === "0") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$router.push({ path: "/404" });
      }
      return this.swords[this.$route.params.id];
    },
  },
};
</script>
